import { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { collection, addDoc, serverTimestamp, updateDoc, doc, query, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes} from 'firebase/storage'

import { database, storage }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css';

export function UserStartup() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()
    const navigate = useNavigate();

    const [nome, setNome] = useState('')
    const [instagram, setInstagram] = useState('')
    const [linkedin, setLinkedin ] = useState('')
    const [descricao, setDescricao ] = useState('')
    const [facebook, setFacebook] = useState('')
    const [url, setUrl] = useState('')
    const [imagem, setImagem] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            if(params.id) {
                const q = query(collection(database, "startups"));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    });

                    items[0].imageUrl ? setImageUrl(items[0].imageUrl) : ''
                    items[0].nome ? setNome(items[0].nome) : ''
                    items[0].instagram ? setInstagram(items[0].instagram) : ''
                    items[0].linkedin ? setLinkedin(items[0].linkedin) : ''
                    items[0].facebook ? setFacebook(items[0].facebook) : ''
                    items[0].url ? setUrl(items[0].url) : ''
                })
            }
        }

        return () => {
            isMounted = false;
        }
    }, [])

    useEffect(() => {
        if(imagem != '') {
            setImageUrl(URL.createObjectURL(imagem[0]))
        }
    }, [imagem])

    async function handleCreation(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        const filename = new Date().getTime()

        if(imagem[0] == null) {
            toast('Item criado com sucesso', { type: 'success' })
            updateDoc(doc(database, 'userSettings', userSettings.id), {
                type: 'inover'
            })

            setTimeout(() => {
                navigate("/user/startups")
            }, 2000)

            return
        }  

        const imageRef = ref(storage, `images/users/${filename + imagem[0].name}`)
        uploadBytes(imageRef, imagem[0]).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((imageUrl) => {
                addDoc(collection(database, 'startups'), {
                    imageUrl,
                    nome,
                    instagram,
                    linkedin,
                    facebook,
                    url,
                    owner: {
                        name: userSettings.name,
                        email: userSettings.email,
                        id: userSettings.id
                    },
                    created_at: serverTimestamp()
                })
                .then(() => {
                    toast('Item criado com sucesso', { type: 'success' })
                    updateDoc(doc(database, 'userSettings', userSettings.id), {
                        type: 'inover'
                    })

                    setTimeout(() => {
                        navigate("/user/startups")
                    }, 2000);
                })
            }) 
        })    
    }

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            if(imagem == ''){
                await updateDoc(doc(database, 'startups', params.id), {
                    nome,
                    instagram,
                    linkedin,
                    facebook,
                    url,
                    owner: {
                        name: userSettings.name,
                        email: userSettings.email,
                        id: userSettings.id
                    }
                })
                .then(() => {
                    toast('Item editado com sucesso', { type: 'success' })
                    setTimeout(() => {
                        navigate("/user/startups")
                    }, 2000);
                })
            } else {
                const filename = new Date().getTime()
                
                const imageRef = ref(storage, `images/users/${filename + imagem[0].name}`)
                uploadBytes(imageRef, imagem[0]).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(async (imageUrl) => {
                        await updateDoc(doc(database, 'startups', params.id), {
                            imageUrl,
                            nome,
                            instagram,
                            linkedin,
                            facebook,
                            url,
                            owner: {
                                name: userSettings.name,
                                email: userSettings.email,
                                id: userSettings.id
                            }
                        })
                        .then(() => {
                            toast('Item editado com sucesso', { type: 'success' })
                            setTimeout(() => {
                                navigate("/user/startups")
                            }, 2000);
                        })
                    }) 
                })
            }  
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Atualizar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    return (
        <>
            { params.id ? (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/editar startup</span>

                                <Link 
                                    to="/user/startups" 
                                    style={{ 
                                        color: '#fff', 
                                        textDecoration: 'none', 
                                        marginBottom: 10, 
                                        display: 'inline-block' 
                                    }}>
                                        /voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleEdit} encType="multipart/form-data">
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <label htmlFor="imagem">
                                                    { imagem == '' ? (
                                                        <div
                                                            style={{
                                                                background: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#000',
                                                                fontSize: 20,
                                                                borderRadius: 50,
                                                                width: 60,
                                                                border: '5px solid #e1e1e1',
                                                                height: 60,
                                                                marginRight: 20,
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            <img 
                                                                src={imageUrl} 
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                                alt="" 
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div 
                                                            style={{ 
                                                                background: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#000',
                                                                fontSize: 20,
                                                                borderRadius: 50,
                                                                width: 60,
                                                                border: '5px solid #e1e1e1',
                                                                height: 60,
                                                                marginRight: 20
                                                            }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></div>
                                                    ) }

                                                    <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                                </label>
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <label htmlFor="nome">Nome</label>
                                                <input type="text" value={nome} onChange={event => setNome(event.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="instagram">Descrição</label>
                                        <textarea type="text" value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="instagram">Instagram</label>
                                        <input type="text" value={instagram} onChange={event => setInstagram(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="linkedin">Linkedin</label>
                                        <input type="text" value={linkedin} onChange={event => setLinkedin(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="facebook">Facebook</label>
                                        <input type="text" value={facebook} onChange={event => setFacebook(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="url">Site</label>
                                        <input type="text" value={url} onChange={event => setUrl(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Atualizar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/cadastrar nova startup</span>

                                <Link 
                                    to="/user/startups" 
                                    style={{ 
                                        color: '#fff', 
                                        textDecoration: 'none', 
                                        marginBottom: 10, 
                                        display: 'inline-block' 
                                    }}>
                                        /voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleCreation} encType="multipart/form-data">
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <label htmlFor="imagem">
                                                { imagem == '' ? (
                                                    <div 
                                                        style={{ 
                                                            background: 'white',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: '#000',
                                                            fontSize: 20,
                                                            borderRadius: 50,
                                                            width: 60,
                                                            border: '5px solid #e1e1e1',
                                                            height: 60,
                                                            marginRight: 20
                                                        }}>+</div>
                                                ) : (
                                                    <div 
                                                        style={{ 
                                                            background: 'white',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            overflow: 'hidden',
                                                            color: '#000',
                                                            fontSize: 20,
                                                            borderRadius: 50,
                                                            width: 60,
                                                            border: '5px solid #e1e1e1',
                                                            height: 60,
                                                            marginRight: 20
                                                        }}>
                                                            <img 
                                                                src={imageUrl} 
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                                alt="" 
                                                            />
                                                        </div>
                                                ) }
                                                

                                                <input 
                                                    type="file" 
                                                    name="imagem" 
                                                    id="imagem" 
                                                    style={{ display: 'none' }} 
                                                    onChange={event => setImagem(event.target.files)}
                                                    required 
                                                />
                                            </label>
                                        </div>

                                        <div style={{ flex: 1 }}>
                                            <label htmlFor="titulo">Nome:</label>
                                            <input 
                                                type="text" 
                                                value={nome} 
                                                onChange={event => setNome(event.target.value)} 
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="instagram">Descrição</label>
                                        <textarea type="text" style={{ height: 120, borderRadius: 10 }} value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="instagram">Instagram</label>
                                        <input type="text" value={instagram} onChange={event => setInstagram(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="linkedin">Linkedin</label>
                                        <input type="text" value={linkedin} onChange={event => setLinkedin(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="facebook">Facebook</label>
                                        <input type="text" value={facebook} onChange={event => setFacebook(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="url">Site</label>
                                        <input type="text" value={url} onChange={event => setUrl(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Cadastrar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            
        </>
    )
}