import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { onSnapshot, collection, query } from "firebase/firestore";

import { database }  from '../services/firebase'

import { AuthContext } from '../App'

import { Header } from '../components/header'

import styles from '../styles/pages/capacitacoes.module.scss';

export function Capacitacoes() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "capacitacoes"))

        let isMounted = true

        if(isMounted) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });
    
                setList(items)
            })
        }

        return () => {
            isMounted = false;
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/capacitações</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    color: '#000', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    background: '#fff',
                                    padding: '5px 10px',
                                    borderRadius: 20,
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            return (
                                <Link to={`/capacitacoes/${item.id}`} key={item.id} style={{ textDecoration: 'none' }}>
                                    <div className={styles.capacitacoesHolder}>
                                        <div className={styles.capacitacoes}>
                                            <div className={styles.capacitacoesThumb}>
                                                <img src={item.imageUrl} alt="" />
                                            </div>

                                            <div className={styles.capacitacoesInfos}>
                                                <span><strong>{item.titulo}</strong></span>
                                                <span><strong>Por:</strong> {item.autor}</span>
                                                <span><strong>Duração:</strong> {item.duracao}</span>

                                                <p>{item.descricao}</p>
                                            </div>
                                        </div>

                                        {/* <div className={styles.capacitacoesProgress}>
                                            <div></div>
                                        </div> */}
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}