import { initializeApp } from 'firebase/app'

import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: 'AIzaSyCyyVOQXEAoat8Nub_2gmUIKZGy4CUpSwY',
    authDomain: 'inovaparq-25c4e.firebaseapp.com',
    projectId: 'inovaparq-25c4e',
    storageBucket: 'inovaparq-25c4e.appspot.com',
    messagingSenderId: '200889064018',
    appId: '1:200889064018:web:a217d8ebad67ffa9e6dd8b',
    measurementId: 'G-CX3QZ6Z18K'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const auth = getAuth()
const database = getFirestore(app)
const storage = getStorage(app)

export { app, auth, database, storage, analytics }