import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query } from 'firebase/firestore'

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'swiper/css'

export function AdminOportunidades() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "oportunidades"))

        let isMounted = true

        if(isMounted) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });
    
                setList(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <Link 
                                to="/admin/oportunidade" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    cadastrar nova oportunidade
                            </Link>

                            <Link 
                                to="/admin" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block', 
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            return (
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <Link to={`/admin/oportunidade/${item.id}`} style={{ textDecoration: 'none' }}>
                                        <div className={styles.capacitacoesHolder}>
                                            <div className={styles.capacitacoes}>
                                                <div className={styles.capacitacoesThumb}>
                                                    <img src={item.imageUrl} alt="" />
                                                </div>

                                                <div className={styles.capacitacoesInfos}>
                                                    <span><strong>{item.titulo}</strong></span>
                                                    <p>{item.descricao}</p>
                                                    <Link to={item.link} className={styles.buttonLight}>
                                                        Saiba Mais
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className={styles.delete}>
                                        <Delete id={item.id} table="oportunidades" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}