import { useContext, useEffect, useState } from 'react'

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/servicosDisponiveis.module.scss';
import 'swiper/css';
import { Link } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { database } from '../services/firebase';

export function Startups() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [residentes, setResidentes] = useState<any | null>([])
    const [parceirosAcademicos, setParceirosAcademicos] = useState<any | null>([])
    const [empresasConveniadas, setEmpresasConveniadas] = useState<any | null>([])

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'residentes')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })

                setResidentes(items)
            })

            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'parceiros-academicos')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })

                setParceirosAcademicos(items)
            })

            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'empresas-conveniadas')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })

                setEmpresasConveniadas(items)
            })
        }

        return () => {
            isMounted = false;
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />
                
                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/quem está no parque</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none', 
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Residentes</span>

                                <div className="img-container">
                                    {residentes.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Parceiros Acadêmicos</span>

                                <div className="img-container">
                                    {parceirosAcademicos.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Empresas conveniadas</span>

                                <p>As empresas abaixo possuem convênios técnico-científicos com o Inovaparq para o desenvolvimento e aplicação de projetos. Sua empresa quer ser uma de nossas parceiras? Entre em contato conosco para saber mais detalhes!</p>

                                <div className="img-container">
                                    {empresasConveniadas.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}