import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { onSnapshot, collection, query, updateDoc, doc, where, orderBy, deleteDoc, addDoc, serverTimestamp } from "firebase/firestore";
import { DefaultUi, Player, Youtube } from '@vime/react'
import { styled } from '@stitches/react';
import { blackA } from '@radix-ui/colors';
import * as SwitchPrimitive from '@radix-ui/react-switch';

import { database }  from '../services/firebase'
import { AuthContext } from '../App'

import { Header } from '../components/header'

import styles from '../styles/pages/capacitacoes.module.scss';
import '@vime/core/themes/default.css'

const StyledSwitch = styled(SwitchPrimitive.Root, {
    all: 'unset',
    width: 42,
    height: 25,
    backgroundColor: blackA.blackA9,
    borderRadius: '9999px',
    position: 'relative',
    boxShadow: `0 2px 10px ${blackA.blackA7}`,
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    '&:focus': {  },
    '&[data-state="checked"]': { backgroundColor: 'green' },
})
  
const StyledThumb = styled(SwitchPrimitive.Thumb, {
    display: 'block',
    width: 21,
    height: 21,
    backgroundColor: 'white',
    borderRadius: '9999px',
    boxShadow: `0 2px 2px ${blackA.blackA7}`,
    transition: 'transform 100ms',
    transform: 'translateX(1px) translateY(2px)',
    willChange: 'transform',
    '&[data-state="checked"]': { transform: 'translateX(19px) translateY(2px)' },
})
  
// Exports
export const Switch = StyledSwitch;
export const SwitchThumb = StyledThumb;

export function Capacitacao() {
    const { user, userSettings } = useContext(AuthContext)
    const [list, setList] = useState([])
    const [lessons, setLessons] = useState([])

    const params = useParams()

    useEffect(() => {
        const q = query(collection(database, 'aulas'), where('capacitacaoId', '==', params.id), orderBy('ordem', 'asc'));
        
        let isMounted = true

        if (isMounted) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const items = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })

                setList(items)
            })

            if(userSettings != undefined) {
                onSnapshot(query(collection(database, 'userLessons'), where('userId', '==', userSettings.id)), (querySnapshot) => {
                    const lessons = [];
                    
                    querySnapshot.forEach((doc) => {
                        const data = doc.data()

                        lessons.push(data.lessonId)
                    })
        
                    setLessons(lessons)
                })
            }
        }

        return () => {
            isMounted = false;
        }
    }, [])

    const handleVideoCompleted = async (e, id, pontuacao) => {
        console.log(e, id)
        if(e === true) {
            addDoc(collection(database, 'userLessons'), {
                lessonId: id,
                userId: userSettings.id,
                created_at: serverTimestamp()
            })

            updateDoc(doc(database, 'userSettings', userSettings.id), {
                points: userSettings.points+pontuacao
            })
        } else {
            onSnapshot(query(collection(database, 'userLessons'), where('lessonId', '==', id)), (querySnapshot) => {
                querySnapshot.forEach((ref) => {
                    deleteDoc(doc(database, 'userLessons', ref.id))
                })
            })

            updateDoc(doc(database, 'userSettings', userSettings.id), {
                points: userSettings.points-pontuacao
            })
        }
    }

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <Link 
                            to="/capacitacoes" 
                            style={{ 
                                color: '#000', 
                                textDecoration: 'none',  
                                display: 'inline-block',
                                background: '#fff',
                                padding: '5px 10px',
                                borderRadius: 20,
                                marginBottom: 20,
                            }}>
                                voltar
                        </Link>

                        {list.map((item) => {
                            const isLessonComplete = lessons.includes(item.id)
                            
                            return (
                                <div key={item.id} className={styles.capacitacoesHolder}>
                                    <div className={styles.capacitacoes} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <div className={styles.capacitacoesThumb} style={{ aspectRatio: '16 / 9', height: 'auto', width: '100%', marginBottom: 10 }}>
                                            {/* <img src={item.imageUrl} alt="" /> */}

                                            <Player controls style={{ width: '100%' }}>
                                                <Youtube videoId={item.idVideo} />
                                                <DefaultUi />
                                            </Player>
                                        </div>

                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div className={styles.capacitacoesInfos} style={{ flex: 1 }}>
                                                <h2 style={{ color: 'white' }}><strong>{item.titulo}</strong></h2>
                                                <span><strong>Pontos:</strong> {item.pontuacao}</span>
                                                <span><strong>Duração:</strong> {item.duracao}</span>
                                            </div>

                                            <div style={{ display: 'flex', marginBottom: 10, textAlign: 'right', justifyContent: 'flex-end' }}>
                                                <span style={{ color: '#fff', display: 'inline-block', marginRight: 10 }}>marcar como <br />concluído</span>
                                                <Switch checked={ isLessonComplete } onCheckedChange={(e, id, pontuacao) => handleVideoCompleted(e, item.id, item.pontuacao)}>
                                                    <SwitchThumb />
                                                </Switch>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}