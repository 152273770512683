import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/comoAbrirUmaStartup.module.scss'
import 'swiper/css'

export function ComoAbrirUmaStartup() {
    const { user, userSettings } = useContext<any>(AuthContext)

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />
                
                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/como abrir uma startup</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Como tirar uma ideia do papel?</span>

                                <p>Dicas para você ter sucesso na “jornada” do processo de inovação.</p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>1</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Inove de verdade!</strong><br /><br />

                                    Entenda que inovar é diferente de inventar.<br /><br />

                                    “A inovação é um jogo de números. Você precisa ter centenas de ideias para encontrar um negócio de sucesso.” Autor: Art Fry
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>2</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Seja inovador!</strong><br /><br />

                                    Esteja disposto a ultrapassar seus próprios limites e supere os medos! Medo do desconhecido, de ser julgado, de dar o primeiro passo, de perder o controle. <br /><br />

                                    “A coragem não é ausência do medo, mas o triunfo sobre ele.” Autor: Nelson Mandela
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>3</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Ache a dor!</strong><br /><br />

                                    Encontre uma dor, uma necessidade, uma demanda, um problema, tente encontrar a fonte do problema, fique atento aos detalhes, tente enxergar o problema de vários ângulos diferentes, faça entrevistas com várias pessoas para confirmar que o problema existe. Identifique o público-alvo e o tamanho do mercado potencial consumidor. Pesquise soluções potencialmente concorrentes.<br /><br />


                                    “Uma pilha de rochas deixa de ser rocha quando alguém a olha com a ideia de uma catedral em mente.” Autor: Antoine de Saint-Exupéry
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>4</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Crie ideias de soluções!</strong><br /><br />

                                    Com o problema bem definido encontre formas de resolver esse problema, combine técnicas, áreas do conhecimento, tecnologias, busque soluções inovadoras ou diferentes, que sejam mais produtivos, mais baratos, mais acessíveis, mais práticos, mais modernos, proporcionam uma qualidade superior, menor complexidade, maior automação e menos necessidade de manutenção. Faça um levantamento dos custos, precificação e análise financeira econômica do produto, serviço e do negócio.<br /><br />


                                    “A sorte favorece a mente conectada.” Autor: Steven Johnson
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>5</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Valide e ajuste a ideia!</strong><br /><br />

                                    Compartilhe, converse com outras pessoas, preferencialmente com especialistas. Pergunte para as pessoas que tem efetivamente o problema que a sua solução resolve. Faça apresentações da sua ideia, use o formato de Pitch, em 3 minutos, e identifique o que precisa ajustar para melhorar cada vez mais o convencimento na sua ideia.<br /><br />


                                    “Se você não está preparado para errar, é improvável que alcance qualquer coisa original”. Autor: Sir Ken Robinson
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>6</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Faça o MVP!</strong><br /><br />

                                    Faça um MVP - Mínimo Produto Viável, realize a primeira entrega ou implementação da solução. Avalie todo o processo de usabilidade, corrija as falhas ou defina as características técnicas e responsabilidades oferecidas pela solução ou ideia.<br /><br />


                                    “O cliente não sabe o que quer até que você diga a ele.” Autor: Steve Jobs
                                </p>
                            </div>
                        </div>

                        <div className={styles.passosStartup}>
                            <div className={styles.passosNumber}>
                                <span>7</span>
                            </div>

                            <div className={styles.passosDescription}>
                                <p>
                                    <strong style={{ fontSize: 18 }}>Pronto, agora é só vender!</strong><br /><br />

                                    O sucesso do MVP vai definir se a ideia é boa ou não. Se a experiência foi boa então é momento de iniciar um negócio, sua ideia pode se tornar uma startup se a solução for inovadora e escalável, por isso, busque se aproximar de instituições ou ambientes que apoiam os novos empreendedores como: incubadoras, parques tecnológicos, aceleradoras, investidores anjo, núcleos de inovação e outros atores do ecossistema de inovação.<br /><br />


                                    “Quem não mede, não gerencia.” Autor: W. Edwards Deming
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}