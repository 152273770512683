import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { onSnapshot, collection, query, where, getDoc } from "firebase/firestore";

import { database }  from '../services/firebase';

import { AuthContext } from '../App';

import { Header } from '../components/header';

import styles from '../styles/pages/servicosDisponiveis.module.scss';

export function Agenda() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    const params = useParams()

    useEffect(() => {
        const q = query(collection(database, "agendas"))

        let isMounted = true
        
        if(isMounted) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    if(doc.id == params.id){
                        items.push({ id: doc.id, ...doc.data() })
                    }
                });
    
                setList(items)
    
                return items
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/agendas</span>

                            <Link 
                                to="/agendas" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            const date = String(new Date(item.data.seconds * 1000).toLocaleDateString('pt-BR', {
                                weekday: 'long' ,
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })).split( ' ' )
                            
                            return (
                                <div key={item.id} className={styles.dashboardHolder}>
                                    <div className={styles.servicosDisponiveisDescription}>
                                        <div className={styles.thumb}>
                                            <img src={item.imageUrl} alt="" />
                                        </div>

                                        <div className={styles.descriptionServices}>
                                            <div className={styles.titleServices}>
                                                <span>{item.titulo}</span>

                                                <p style={{ marginBottom: 0 }}>
                                                    <small style={{ opacity: .6, fontWeight: 'normal' }}>{ date[1] } { date[2] } { date[3] }</small>
                                                    { item?.hora && <small style={{ opacity: .6, fontWeight: 'normal' }}> - { item?.hora }</small> }
                                                </p>

                                                <p>{item.descricao}</p>
                                            </div>
                                        </div>

                                        <div className={styles.servicesButton}>
                                            <a href={item.link} target="_blank">Acesse o site oficial</a>
                                        </div>
                                    </div>  
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}