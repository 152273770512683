import { ToastContainer, toast } from 'react-toastify';
import { deleteDoc, doc } from "firebase/firestore"
import { confirmAlert } from 'react-confirm-alert';

import { database } from "../../services/firebase"

import 'react-confirm-alert/src/react-confirm-alert.css';

export function Delete({ id, table }) {
    const handleDelete = async (e) => {
        try {
            e.target.innerHTML = `
                <svg 
                    width="24" 
                    height="24" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    class="spin feather feather-loader"
                >
                    <line x1="12" y1="2" x2="12" y2="6"></line>
                    <line x1="12" y1="18" x2="12" y2="22"></line>
                    <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                    <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                    <line x1="2" y1="12" x2="6" y2="12"></line>
                    <line x1="18" y1="12" x2="22" y2="12"></line>
                    <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                    <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
                </svg>
            `
            e.target.setAttribute('disabled', 'disabled')

            confirmAlert({
                title: 'Clique para confirmar',
                message: 'Você quer deletar este item?',
                buttons: [
                  {
                    label: 'Sim',
                    onClick: async () => {
                        await deleteDoc(doc(database, table, id))
                        
                        toast('Item deletado com sucesso', { type: 'success' })
                    }
                  },
                  {
                    label: 'Não',
                    onClick: () => {
                        e.target.innerHTML = 'Deletar'
                        e.target.removeAttribute('disabled')
                    }
                  }
                ]
              });
        } catch(error) {
            toast('Tivemos um problema ao deletera este item', { type: 'error' })
            console.log(error)
        }
    } 

    return (
        <>
            <button onClick={handleDelete}>Deletar</button>
        </>
    )
}