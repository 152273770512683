import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { collection, addDoc, serverTimestamp, updateDoc, doc, query, onSnapshot, where, orderBy } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes} from 'firebase/storage'

import { database, storage }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from './delete'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css'

export function AdminCapacitacao() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()

    const [id, setId] = useState('')
    const [titulo, setTitulo] = useState('')
    const [autor, setAutor] = useState('')
    const [duracao, setDuracao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [imagem, setImagem] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [listAulas, setListAulas] = useState([])

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            if(params.id) {
                const q = query(collection(database, "capacitacoes"))
    
                onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    });
    
                    setId(items[0].id)
                    setImageUrl(items[0].imageUrl)
                    setTitulo(items[0].titulo)
                    setAutor(items[0].autor)
                    setDuracao(items[0].duracao)
                    setDescricao(items[0].descricao)
    
                    return items
                })
    
                onSnapshot(query(collection(database, 'aulas'), where('capacitacaoId', '==', params.id), orderBy('ordem', 'asc')), (querySnapshot) => {
                    const aulas = [];
                    
                    querySnapshot.forEach((doc) => {
                        aulas.push({ id: doc.id, ...doc.data() })
                    })
    
                    setListAulas(aulas)
                    console.log(listAulas)
                });
            }
        }

        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        if(imagem != '') {
            setImageUrl(URL.createObjectURL(imagem[0]))
        }
    }, [imagem])

    async function handleCreation(event) {
        event.preventDefault()

        const filename = new Date().getTime()

        if(imagem[0] == '') return

        const imageRef = ref(storage, `images/${filename + imagem[0].name}`)
        uploadBytes(imageRef, imagem[0]).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((imageUrl) => {
                addDoc(collection(database, 'capacitacoes'), {
                    imageUrl,
                    titulo,
                    autor,
                    duracao,
                    descricao,
                    created_at: serverTimestamp()
                })
                .then(() => {
                    toast('Item criado com sucesso', { type: 'success' })
                    
                    location.href = '/admin/capacitacoes'
                })
            }) 
        })    
    }

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            if(imagem == ''){
                await updateDoc(doc(database, 'capacitacoes', params.id), {
                    titulo,
                    autor,
                    duracao,
                    descricao
                })
            } else {
                const filename = new Date().getTime()
                
                const imageRef = ref(storage, `images/${filename + imagem[0].name}`)
                await uploadBytes(imageRef, imagem[0]).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(async (imageUrl) => {
                        await updateDoc(doc(database, 'capacitacoes', params.id), {
                            imageUrl,
                            titulo,
                            autor,
                            duracao,
                            descricao
                        })
                    }) 
                })
            }  
            
            toast('Item editado com sucesso', { type: 'success' })
            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    return (
        <>
            { params.id ? (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/editar capacitação</span>

                                <Link 
                                    to="/admin/capacitacoes" 
                                    style={{ 
                                        color: '#000', 
                                        textDecoration: 'none', 
                                        display: 'inline-block',
                                        padding: '5px 10px',
                                        background: '#fff',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleEdit} encType="multipart/form-data">
                                    <div>
                                        <label htmlFor="imagem">
                                            { imageUrl == '' ? (
                                                <div className={styles.thumbnail}>+</div>
                                            ) : (
                                                <div className={styles.thumbnail}>
                                                    <img 
                                                        src={imageUrl} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ) }

                                            <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="titulo">Título</label>
                                        <input type="text" value={titulo} onChange={event => setTitulo(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="autor">Autor</label>
                                        <input type="text" value={autor} onChange={event => setAutor(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="duracao">Duração</label>
                                        <input type="text" value={duracao} onChange={event => setDuracao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="descricao">Descrição</label>
                                        <textarea value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Editar</button>
                                    </div>
                                </form>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <h2 style={{ color: 'white' }}>Aulas desta capacitação</h2>
                                
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                    <Link 
                                        to={`/admin/aula/${params.id}`} 
                                        style={{ 
                                            color: '#fff', 
                                            textDecoration: 'none', 
                                            marginBottom: 10, 
                                            display: 'inline-block' 
                                        }}>
                                            /criar nova aula
                                    </Link>
                                </div>

                                { listAulas.map(aula => {
                                    return(
                                        <div key={aula.id} style={{ position: 'relative' }}>
                                            <Link to={`/admin/aula/${ aula.id }/${ params.id }`} style={{ textDecoration: 'none' }}>
                                                <div className={styles.capacitacoesHolder}>
                                                    <div className={styles.capacitacoes}>
                                                        <div className={styles.capacitacoesThumb}>
                                                            <img src={aula.imageUrl} alt="" />
                                                        </div>

                                                        <div className={styles.capacitacoesInfos}>
                                                            <span><strong>{aula.titulo}</strong></span>
                                                            <span><strong>Duração:</strong> {aula.duracao}</span>
                                                            <span><strong>Pontos:</strong> {aula.pontuacao}</span>
                                                            <span><strong>Ordem:</strong> {aula.ordem}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <div className={styles.delete}>
                                                <Delete id={aula.id} table="aulas" />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/cadastrar nova capacitação</span>

                                <Link 
                                    to="/admin/capacitacoes" 
                                    style={{ 
                                        color: '#fff', 
                                        textDecoration: 'none', 
                                        marginBottom: 10, 
                                        display: 'inline-block' 
                                    }}>
                                        /voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleCreation} encType="multipart/form-data">
                                    <div>
                                        <label htmlFor="imagem">
                                            { imageUrl == '' ? (
                                                <div className={styles.thumbnail}>+</div>
                                            ) : (
                                                <div className={styles.thumbnail}>
                                                    <img 
                                                        src={imageUrl} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ) }

                                            <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="titulo">Título</label>
                                        <input type="text" value={titulo} onChange={event => setTitulo(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="autor">Autor</label>
                                        <input type="text" value={autor} onChange={event => setAutor(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="duracao">Duração</label>
                                        <input type="text" value={duracao} onChange={event => setDuracao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="descricao">Descrição</label>
                                        <textarea value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Cadastrar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            
        </>
    )
}