import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { onSnapshot, doc, collection, query, where, getDoc } from "firebase/firestore";

import { database, auth }  from '../services/firebase';

import { AuthContext } from '../App';

import { Header } from '../components/header';

import styles from '../styles/pages/servicosDisponiveis.module.scss';

export function Startup() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    const params = useParams()

    useEffect(() => {
        const q = query(collection(database, "startups-no-parque"));
        
        let isMounted = true

        if(isMounted) {
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    if(doc.id == params.id){
                        items.push({ id: doc.id, ...doc.data() })
                    }
                });
    
                setList(items)
    
                return items
            });
        }

        return () => {
            isMounted = false;
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>

                        {list.map((item: any) => {
                            return (
                                <div key={item.id} className={styles.dashboardHolder}>
                                    <div className={styles.servicosDisponiveisDescription}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                            <span>/startup</span>

                                            <Link 
                                                to="/startups" 
                                                style={{ 
                                                    color: '#000', 
                                                    textDecoration: 'none', 
                                                    display: 'inline-block',
                                                    background: '#fff',
                                                    padding: '5px 10px',
                                                    borderRadius: 20
                                                }}>
                                                    voltar
                                            </Link>
                                        </div>

                                        <div className="img-container" style={{ gridTemplateColumns: '1fr' }}>        
                                            <div className="img-holder">
                                                <img src={item.imageUrl} alt="" />
                                            </div> 
                                        </div>

                                        <div className={styles.descriptionServices}>
                                            <div className={styles.titleServices}>
                                                <span>{item.nome}</span>
                                                <p>{item.descricao}</p>
                                            </div>
                                        </div>

                                        { item?.url && 
                                            <div className={styles.servicesButton}>
                                                <a href={item.url} target="_blank">Acesse o site oficial</a>
                                            </div> 
                                        }

                                        <div className={styles.socialsButton}>
                                            <span>ou nossas redes sociais:</span>

                                            { item?.facebook && 
                                                <a href={item.facebook} target="_blank">
                                                    <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"/></svg>
                                                </a>
                                            }

                                            { item?.instagram && 
                                                <a href={item.instagram} target="_blank">
                                                    <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"/></svg>
                                                </a>
                                            }

                                            { item?.linkedin && 
                                                <a href={item.linkedin} target="_blank">
                                                    <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M10.496,8.403 c0.842,0,1.403,0.561,1.403,1.309c0,0.748-0.561,1.309-1.496,1.309C9.561,11.022,9,10.46,9,9.712C9,8.964,9.561,8.403,10.496,8.403z M12,20H9v-8h3V20z M22,20h-2.824v-4.372c0-1.209-0.753-1.488-1.035-1.488s-1.224,0.186-1.224,1.488c0,0.186,0,4.372,0,4.372H14v-8 h2.918v1.116C17.294,12.465,18.047,12,19.459,12C20.871,12,22,13.116,22,15.628V20z"/></svg>
                                                </a>
                                            }
                                        </div>
                                    </div>  
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}