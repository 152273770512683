import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query } from "firebase/firestore"

import { database }  from '../services/firebase'

import { AuthContext } from '../App'

import { Header } from '../components/header'

import styles from '../styles/pages/aconteceAquiAlunos.module.scss'

export function AconteceAqui() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "acontecemAqui"))

        let isMounted = true

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });
    
                setList(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/acontece aqui</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            const date = String(new Date(item.data.seconds * 1000).toLocaleDateString('pt-BR', {
                                weekday: 'long' ,
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })).split( ' ' )

                            const weekDay = date[0].split('-')

                            return (
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <div className={styles.aconteceAquiEventos}>
                                        <div className={styles.aconteceAquiData}>
                                            <span>{item.date}</span>

                                            <p>{ date[3] }</p>

                                            <small>dia { date[1]} |  { weekDay[0] }</small> 
                                        </div>

                                        <div className={styles.AconteceAquiDescription}>
                                            <div className={styles.aconteciAquiDescriptionHeader}>
                                                <figure>
                                                    <img src={item.imageUrl} alt="" style={{ objectFit: 'cover' }} />
                                                </figure>

                                                <div className={styles.aconteceAquiTitle}>
                                                    <p>{item.titulo}</p>

                                                    <Link to={`/acontece-aqui/${item.id}`} className={styles.buttonBlue}>
                                                        Saiba Mais
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className={styles.eventDescription}>
                                                <p>{item.descricao.substring(0, 140)}...</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}