import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { collection, addDoc, Timestamp, serverTimestamp, updateDoc, doc, query, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes} from 'firebase/storage'

import { database, storage }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css'

export function AdminAgenda() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()
    const navigate = useNavigate();

    const [titulo, setTitulo] = useState('')
    const [local, setLocal] = useState('')
    const [data, setData] = useState('')
    const [hora, setHora] = useState('')
    const [descricao, setDescricao] = useState('')
    const [link, setLink] = useState('')
    const [imagem, setImagem] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            if(params.id) {
                const q = query(collection(database, "agendas"))
                onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    });
    
                    setImageUrl(items[0].imageUrl)
                    setTitulo(items[0].titulo)
                    setLocal(items[0].local)
                    setData(new Date(items[0].data.seconds * 1000).toLocaleDateString('pt-BR'))
                    setHora(items[0].hora)
                    setDescricao(items[0].descricao)
                    setLink(items[0].link)
    
                    return items
                });
            }
        }
        
        return () => {
            isMounted = false
        }
    }, [])

    useEffect(() => {
        if(imagem != '') {
            setImageUrl(URL.createObjectURL(imagem[0]))
        }
    }, [imagem])

    useEffect(() =>{
        Inputmask({"mask": "99/99/9999"}).mask('#data')
    }, [])

    async function handleCreation(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        if(imagem[0] == null) {
            toast('Imagem obrigatória', { type: 'error' })

            document.querySelector('button').innerHTML = `Cadastrar`
            document.querySelector('button').removeAttribute('disabled')

            return
        } 

        const filename = new Date().getTime()

        const dataEN = data.split('/')
        const dataOK = `${ dataEN[1] }/${ dataEN[0] }/${ dataEN[2] }`

        const imageRef = ref(storage, `images/${filename + imagem[0].name}`)
        uploadBytes(imageRef, imagem[0]).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((imageUrl) => {
                addDoc(collection(database, 'agendas'), {
                    imageUrl,
                    titulo,
                    local,
                    data: Timestamp.fromDate(new Date(dataOK)),
                    hora,
                    descricao,
                    link,
                    created_at: serverTimestamp()
                })
                .then(() => {
                    toast('Item criado com sucesso', { type: 'success' })

                    setTimeout(() => {
                        navigate("/admin/agendas")
                    }, 2000);
                })
            }) 
        })
    }

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            if(imagem == ''){
                const dataEN = data.split('/')
                const dataOK = `${ dataEN[1] }/${ dataEN[0] }/${ dataEN[2] }`

                await updateDoc(doc(database, 'agendas', params.id), {
                    titulo,
                    local,
                    data: Timestamp.fromDate(new Date(dataOK)),
                    hora,
                    descricao,
                    link,
                })
            } else {
                const filename = new Date().getTime()
                const dataEN = data.split('/')
                const dataOK = `${ dataEN[1] }/${ dataEN[0] }/${ dataEN[2] }`
                
                const imageRef = ref(storage, `images/${filename + imagem[0].name}`)
                await uploadBytes(imageRef, imagem[0]).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(async (imageUrl) => {
                        await updateDoc(doc(database, 'agendas', params.id), {
                            imageUrl,
                            titulo,
                            local,
                            hora,
                            data: Timestamp.fromDate(new Date(dataOK)),
                            descricao,
                            link,
                        })
                    }) 
                })
            }  
            
            toast('Item editado com sucesso', { type: 'success' })

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    return (
        <>
            { params.id ? (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/editar agenda</span>

                                <Link 
                                    to="/admin/agendas" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none',  
                                        display: 'inline-block' , 
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleEdit} encType="multipart/form-data">
                                    <div>
                                        <label htmlFor="imagem">
                                            { imageUrl == '' ? (
                                                <div className={styles.thumbnail}>+</div>
                                            ) : (
                                                <div className={styles.thumbnail}>
                                                    <img 
                                                        src={imageUrl} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ) }

                                            <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="titulo">Título</label>
                                        <input type="text" value={titulo} onChange={event => setTitulo(event.target.value)} required/>
                                    </div>

                                    <div>
                                        <label htmlFor="local">local</label>
                                        <input type="text" value={local} onChange={event => setLocal(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="data">Data</label>
                                        <input type="text" id="data" value={data} onChange={event => setData(event.target.value)} required/>
                                    </div>

                                    <div>
                                        <label htmlFor="hora">Hora</label>
                                        <input type="text" value={hora} onChange={event => setHora(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="descricao">Descrição</label>
                                        <textarea value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="link">Link</label>
                                        <input type="text" value={link} onChange={event => setLink(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Editar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/cadastrar nova agenda</span>

                                <Link 
                                    to="/admin/agendas" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none',  
                                        display: 'inline-block' , 
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleCreation} encType="multipart/form-data">
                                    <div>
                                        <label htmlFor="imagem">
                                            { imageUrl == '' ? (
                                                <div className={styles.thumbnail}>+</div>
                                            ) : (
                                                <div className={styles.thumbnail}>
                                                    <img 
                                                        src={imageUrl} 
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                        alt="" 
                                                    />
                                                </div>
                                            ) }

                                            <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                        </label>
                                    </div>

                                    <div>
                                        <label htmlFor="titulo">Título</label>
                                        <input type="text" value={titulo} onChange={event => setTitulo(event.target.value)} required/>
                                    </div>

                                    <div>
                                        <label htmlFor="local">Local</label>
                                        <input type="text" value={local} onChange={event => setLocal(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="data">Data</label>
                                        <input type="text" id="data" value={data} onChange={event => setData(event.target.value)} required/>
                                    </div>

                                    <div>
                                        <label htmlFor="hora">Hora</label>
                                        <input type="text" value={hora} onChange={event => setHora(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="descricao">Descrição</label>
                                        <textarea value={descricao} onChange={event => setDescricao(event.target.value)} />
                                    </div>

                                    <div>
                                        <label htmlFor="link">Link</label>
                                        <input type="text" value={link} onChange={event => setLink(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Cadastrar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            
        </>
    )
}