import { useEffect, useState, createContext } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { onAuthStateChanged } from "firebase/auth"
import { onSnapshot, collection, query, where } from "firebase/firestore"

import { database, auth }  from './services/firebase'

import { Login } from "./pages/login"
import { Passaporte } from "./pages/passaporte"

import { Home } from "./pages/home"

import { UserPerfil } from "./pages/user/perfil"
import { UserStartup } from "./pages/user/startup"
import { UserStartups } from "./pages/user/startups"

import { Alunos } from "./pages/alunos"
import { Destaque } from "./pages/destaque"
import { Inovaparq } from "./pages/inovaparq"
import { Startups } from "./pages/startups"
import { Startup } from "./pages/startup"
import { OndeEstamos } from "./pages/onde-estamos"
import { ServicosDisponiveis } from "./pages/servicos-disponiveis"
import { ServicoDisponivel } from "./pages/servico-disponivel"
import { Capacitacoes } from "./pages/capacitacoes"
import { Capacitacao } from "./pages/capacitacao"
import { Oportunidades } from "./pages/oportunidades"
import { Oportunidade } from "./pages/oportunidade"
import { AconteceAqui } from "./pages/acontece-aqui"
import { ComoAbrirUmaStartup } from "./pages/como-abrir-uma-startup"
import { JornadaDoEmpreendedor } from "./pages/jornada-do-empreendedor"
import { BolsasEstagios } from "./pages/bolsas-estagios"
import { BolsaEstagio } from "./pages/bolsa-estagio"


import { Admin } from "./pages/admin"
import { AdminCapacitacoes } from "./pages/admin/capacitacoes"
import { AdminCapacitacao } from "./pages/admin/capacitacao"
import { AdminAula } from "./pages/admin/aula"
import { AdminEditAula } from "./pages/admin/editAula"

import { AdminServicoDisponivel } from "./pages/admin/servico-disponivel"
import { AdminServicosDisponiveis } from "./pages/admin/servicos-disponiveis"

import { AdminAconteceAqui } from "./pages/admin/acontece-aqui"
import { AdminAcontecemAqui } from "./pages/admin/acontecem-aqui"

import { AdminDestaque } from "./pages/admin/destaque"
import { AdminDestaques } from "./pages/admin/destaques"

import { AdminOportunidade } from "./pages/admin/oportunidade"
import { AdminOportunidades } from "./pages/admin/oportunidades"

import { AdminBolsaEstagio } from "./pages/admin/bolsa-estagio"
import { AdminBolsasEstagios } from "./pages/admin/bolsas-estagios"

import { AdminAgenda} from "./pages/admin/agenda"
import { AdminAgendas } from "./pages/admin/agendas"

import { AdminContato } from "./pages/admin/contato"
import { AdminContatos } from "./pages/admin/contatos"

import { AcontecemAqui } from "./pages/acontecem-aqui"

import { Agenda } from "./pages/agenda"
import { Agendas } from "./pages/agendas"

import { AdminStartup } from "./pages/admin/startup"
import { AdminStartups } from "./pages/admin/startups"

export const AuthContext = createContext({})

function App() {
  const [user, setUser] = useState<any | null>(null)
  const [userSettings, setUserSettings] = useState<any | null>(null)
  const [userCourses, setUserCourses] = useState<any | null>(null)

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, setUser)

    let isMounted = true

    if(user) {
      const q = query(collection(database, 'userSettings'), where('email', '==', user.email))

      if (isMounted) {
        onSnapshot(q, querySnapshot => {
          const data = querySnapshot.docs.map(doc => {
            return {
              id: doc.id,
              ...doc.data()
            }
          }) as any
  
          setUserSettings({
            id: data[0].id,
            name: data[0]?.name, 
            imageUrl: data[0]?.imageUrl,
            email: data[0]?.email, 
            type: data[0]?.type,
            points: data[0]?.points,
            certificates: data[0]?.certificates
          })
        })
      }
    }

    return () => {
      isMounted = false;
    }
  }, [user])

  return (
    // basename="/dev"
    <BrowserRouter basename="/app">
      { user ? 
        (
          <AuthContext.Provider value={{ user, userSettings, userCourses }}>
            <Routes>
              <Route path ="/" element={<Home />} />

              <Route path ="/user/perfil/:id" element={<UserPerfil />} />
              <Route path ="/user/startups" element={<UserStartups />} />
              <Route path ="/user/startup" element={<UserStartup />} />
              <Route path ="/user/startup/:id" element={<UserStartup />} />

              <Route path ="/destaque/:id" element={<Destaque />} />
              <Route path ="/inovaparq" element={<Inovaparq />} />
              <Route path ="/startups" element={<Startups />} />
              <Route path ="/startup/:id" element={<Startup />} />
              <Route path ="/onde-estamos" element={<OndeEstamos />} />
              <Route path ="/alunos" element={<Alunos />} />
              <Route path ="/servicos-disponiveis" element={<ServicosDisponiveis />} />
              <Route path ="/servicos-disponiveis/:id" element={<ServicoDisponivel />} />
              <Route path ="/capacitacoes" element={<Capacitacoes />} />
              <Route path ="/capacitacoes/:id" element={<Capacitacao />} />
              <Route path ="/oportunidades" element={<Oportunidades />} />
              <Route path ="/oportunidades/:id" element={<Oportunidade />} />
              <Route path ="/acontece-aqui/:id" element={<AcontecemAqui />} />
              <Route path ="/acontece-aqui" element={<AconteceAqui />} />
              <Route path ="/como-abrir-uma-startup" element={<ComoAbrirUmaStartup />} />
              <Route path ="/jornada-do-empreendedor" element={<JornadaDoEmpreendedor />} />
              <Route path ="/bolsas-estagios" element={<BolsasEstagios />} />
              <Route path ="/bolsas-estagios/:id" element={<BolsaEstagio />} />
              <Route path ="/agendas" element={<Agendas />} />
              <Route path ="/agendas/:id" element={<Agenda />} />

              <Route path ="/admin" element={<Admin />} />
              <Route path ="/admin/capacitacoes" element={<AdminCapacitacoes />} />
              <Route path ="/admin/capacitacao" element={<AdminCapacitacao />} />
              <Route path ="/admin/capacitacao/:id" element={<AdminCapacitacao />} />
              <Route path ="/admin/aula/:id" element={<AdminAula />} />
              <Route path ="/admin/aula/:id/:curso" element={<AdminEditAula />} />

              <Route path ="/admin/servicos-disponiveis" element={<AdminServicosDisponiveis />} />
              <Route path ="/admin/servico-disponivel" element={<AdminServicoDisponivel />} />
              <Route path ="/admin/servico-disponivel/:id" element={<AdminServicoDisponivel />} />

              <Route path ="/admin/acontece-aqui" element={<AdminAconteceAqui />} />
              <Route path ="/admin/acontecem-aqui" element={<AdminAcontecemAqui />} />
              <Route path ="/admin/acontece-aqui/:id" element={<AdminAconteceAqui />} />

              <Route path ="/admin/destaque" element={<AdminDestaque />} />
              <Route path ="/admin/destaques" element={<AdminDestaques />} />
              <Route path ="/admin/destaque/:id" element={<AdminDestaque />} />

              <Route path ="/admin/oportunidade" element={<AdminOportunidade />} />
              <Route path ="/admin/oportunidades" element={<AdminOportunidades />} />
              <Route path ="/admin/oportunidade/:id" element={<AdminOportunidade />} />

              <Route path ="/admin/bolsas-estagios" element={<AdminBolsasEstagios />} />
              <Route path ="/admin/bolsa-estagio" element={<AdminBolsaEstagio />} />
              <Route path ="/admin/bolsa-estagio/:id" element={<AdminBolsaEstagio />} />

              <Route path ="/admin/agendas" element={<AdminAgendas />} />
              <Route path ="/admin/agenda" element={<AdminAgenda />} />
              <Route path ="/admin/agenda/:id" element={<AdminAgenda />} />

              <Route path ="/admin/contatos" element={<AdminContatos />} />
              <Route path ="/admin/contato" element={<AdminContato />} />
              <Route path ="/admin/contato/:id" element={<AdminContato />} />

              <Route path ="/admin/startups" element={<AdminStartups />} />
              <Route path ="/admin/startup" element={<AdminStartup />} />
              <Route path ="/admin/startup/:id" element={<AdminStartup />} />
            </Routes>
          </AuthContext.Provider>
        ) : (
          <Routes>
            <Route path ="/" element={<Login />} />
            <Route path ="/passaporte" element={<Passaporte />} />
          </Routes>
        ) 
      } 
    </BrowserRouter>
  );
}

export default App;
