import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query, orderBy } from "firebase/firestore"

import { database }  from '../../services/firebase'
import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/aconteceAquiAlunos.module.scss'
import 'swiper/css'

export function AdminAcontecemAqui() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "acontecemAqui"), orderBy('data'))
        
        let isMounted = true 

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = []
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setList(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <Link 
                                to="/admin/acontece-aqui" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    cadastrar novo evento
                            </Link>

                            <Link 
                                to="/admin" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block', 
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            const date = String(new Date(item.data.seconds * 1000).toLocaleDateString('pt-BR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })).split(' ')

                            return (
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <Link to={`/admin/acontece-aqui/${item.id}`} style={{ textDecoration: 'none' }}>
                                        <div className={styles.aconteceAquiEventos}>
                                            <div className={styles.aconteceAquiData}>
                                                <span>{item.date}</span>

                                                <p style={{ textTransform: 'capitalize' }}>{ date[3] }</p>

                                                <small>{ date[1] }  |  { item.local }</small> 
                                            </div>

                                            <div className={styles.AconteceAquiDescription}>
                                                <div className={styles.aconteciAquiDescriptionHeader}>
                                                    <figure>
                                                        <img src={item.imageUrl} alt="" />
                                                    </figure>

                                                    <div className={styles.aconteceAquiTitle}>
                                                        <p>{item.titulo}</p>

                                                        <p>{item.subtitulo}</p>
                                                    </div>
                                                </div>

                                                <div className={styles.eventDescription}>
                                                    <p>{item.descricao}</p>
                                                </div>
                                            </div>
                                        </div> 
                                    </Link>

                                    <div className={styles.delete}>
                                        <Delete id={item.id} table="acontecemAqui" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}