import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { onSnapshot, collection, query } from 'firebase/firestore'

import { database }  from '../services/firebase'

import { AuthContext } from '../App'

import { Header } from '../components/header'

import styles from '../styles/pages/servicosDisponiveis.module.scss'

export function ServicoDisponivel() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    const params = useParams()

    useEffect(() => {
        const q = query(collection(database, "servicosDisponiveis"))

        let isMounted = true
        
        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    if(doc.id == params.id){
                        items.push({ id: doc.id, ...doc.data() })
                    }
                });
    
                setList(items)
    
                return items
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/serviços disponíveis</span>

                            <Link 
                                to="/servicos-disponiveis" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                    {list.map((item: any) => {
                        return (
                            <div key={item.id} className={styles.dashboardHolder}>
                                <div className={styles.servicosDisponiveisDescription}>
                                    <div className={styles.thumb}>
                                            <img src={item.imageUrl} alt="" />
                                    </div>

                                    <div className={styles.titleServices}>

                                        <span>{item.titulo}</span>

                                        <p>{item.descricao}</p>

                                        <p>{item.tipo}</p>

                                        <p>Local: {item.sala}</p>

                                        <div className="servicos-botoes">
                                            { item.whatsapp ? (
                                                <a href={`https://api.whatsapp.com/send?phone=55${ item.whatsapp.replace(/\D/g, '') }`} target="_blank" className="whatsapp-button">Whatsapp</a>
                                            ) : null }

                                            { item.contato ? (
                                                <a href={`tel:${ item.contato.replace(/\D/g, '') }`} className="contato-button">Contato</a>
                                            ) : null }

                                            { item.email ? (
                                                <a href={`mailto:${ item.email }`} className="email-button">Email</a>
                                            ) : null }

                                            { item.link ? (
                                                <a href={`${ item.link }`} target="_blank" className="contato-button">Saiba mais</a>
                                            ) : null }
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}