import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { onSnapshot, collection, query } from 'firebase/firestore'

import { database }  from '../services/firebase'

import { AuthContext } from '../App'

import { Header } from '../components/header'

import styles from '../styles/pages/capacitacoes.module.scss'

export function ServicosDisponiveis() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "servicosDisponiveis"))

        let isMounted = true

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });
    
                setList(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/serviços disponíveis</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            return (
                                <div key={item.id} className={styles.capacitacoesHolder}>
                                    <div className={styles.capacitacoes}>
                                        <div className={styles.capacitacoesThumb}>
                                            <img src={item.imageUrl} alt="" />
                                        </div>

                                        <div className={styles.capacitacoesInfos}>
                                            <span><strong>{item.titulo}</strong></span>
                                            <p>{ item.descricao.substring(0, 80) }...</p>
                                            <Link to={`/servicos-disponiveis/${item.id}`} style={{ textDecoration: 'none' }} className={styles.buttonLight}>
                                                Saiba Mais
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}