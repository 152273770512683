import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/servicosDisponiveis.module.scss';
import 'swiper/css';

export function OndeEstamos() {
    const { user, userSettings } = useContext<any>(AuthContext)

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />
                
                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/onde estamos</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <p>O Inovaparq é um parque urbano e está localizado na região norte de Joinville/SC, próximo às instalações da universidade parceira Univille, com acesso fácil à BR 101 e ao aeroporto da cidade. O local também fica perto de grandes indústrias e do parceiro Perini Business Park, que abriga diversas empresas de grande e pequeno porte de diversos segmentos.</p>

                                <img src="https://inovaparq.com.br/wp-content/uploads/2021/04/map.png" style={{ width: '100%' }} alt="" />
                            </div>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Porque Joinville?</span>

                                <p>Pelo lugar de destaque que a cidade ocupa na economia no país: é a segunda maior de Santa Catarina e a quarta do sul do Brasil. Essa localização auxilia o Inovaparq em um de seus principais objetivos, o de promover a interação entre academia e mercado, já que Joinville acolhe muitos dos mais representativos grupos do país de diversos setores, como Lepper, Amanco, Schulz, Docol, Döhler, Whirlpool, Embraco, Ciser, Tigre, Tupy, Totvs, Krona e Wetzel.</p>
                            </div>
                        </div>

                        <div className={styles.servicesButton}>
                            <a href="https://www.google.com.br/maps/place/Inovaparq/@-26.13248,-48.9291776,12z/data=!3m1!4b1" target="_blank">Acesse o mapa e veja mais detalhes.</a>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}