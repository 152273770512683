import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/servicosDisponiveis.module.scss';
import 'swiper/css';

export function Inovaparq() {
    const { user, userSettings } = useContext<any>(AuthContext)

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />
                
                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/conheça o Inovaparq</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>O Inovaparq</span>

                                <p>Assista o vídeo e conheça as startups que estão transformando boas ideias em negócios inovadores com o apoio do Inovaparq.</p>

                                <iframe src="https://www.youtube.com/embed/EKQYNMBeDqQ?controls=0" style={{ marginBottom: 30 }} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                            </div>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>O Inovaparq oferece diversos benefícios para as startups incubadas.</span>

                                <p><strong>Em infraestrutura oferecemos:</strong><br /> Sala de coworking, sala de reunião, acesso a Internet, vigilância e infraestrutura em geral, e estacionamento.</p>

                                <p><strong>As ferramentas disponíveis podem ser:</strong><br /> Acesso à U$ 10 mil em créditos para utilização da plataforma Amazon Web Services (AWS), 100 mil e-mails por mês na ferramenta SendGrid, assinatura grátis da ferramenta de gestão empresarial ContaAzul, um ano de acesso à ferramenta de gerenciamento de projetos Artia, e outras ferramentas.</p>

                                <p>Oferecemos também networking com: Fundos de investimento, investidores-anjo, universidades, empresas, governo, empreendedores, mentores e especialistas.</p>

                                <p><strong>Os serviços oferecidos são por meio da:</strong><br /> Agência Beta de Publicidade e Propaganda, acessoria juridica, diversos laboratórios para pesquisa, Núcleo de Inovação e Propriedade Intelectual (NIPI), Fábrica de Software e outros.</p>

                                <p><strong>Ainda temos orientação especial com:</strong><br /> sessões de mentoria, reuniões de monitoramento com indicadores de maturidade, apoio no processo de abertura de empresa, capacitações e consultorias e encontros com os incubados para pitch e troca de experiências.</p>

                                <p><strong>As facilidades são:</strong><br /> restaurantes, shopping center, academia, biblioteca, auditórios e anfiteatros.</p>

                                <p><strong>E oferecemos também os programas educacionais de apoio que são:</strong><br /> Agitte Agência de Inovação e Transferência de Tecnologia da Univille, Beta Agência Experimental, Clínica de direito, Fábrica de software, NAF Núcleo de Apoio Contábil e Fiscal, Observatório de Economia, PIER Programa de Internacionalização de Empresas e Posso Programa de Empreendedorismo.</p>
                            </div>
                        </div>

                        <div className={styles.servicesButton}>
                            <a href="https://inovaparq.com.br/" target="_blank">Acesse nosso site</a>
                        </div>

                        <div className={styles.socialsButton}>
                            <span>ou nossas redes sociais:</span>

                            <a href="https://www.facebook.com/inovaparq" target="_blank">
                                <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.016,4.432,10.984,10.206,11.852V18.18h-2.969v-3.154h2.969v-2.099c0-3.475,1.693-5,4.581-5 c1.383,0,2.115,0.103,2.461,0.149v2.753h-1.97c-1.226,0-1.654,1.163-1.654,2.473v1.724h3.593L19.73,18.18h-3.106v8.697 C22.481,26.083,27,21.075,27,15C27,8.373,21.627,3,15,3z"/></svg>
                            </a>

                            <a href="https://www.instagram.com/inovaparq/" target="_blank">
                                <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M 9.9980469 3 C 6.1390469 3 3 6.1419531 3 10.001953 L 3 20.001953 C 3 23.860953 6.1419531 27 10.001953 27 L 20.001953 27 C 23.860953 27 27 23.858047 27 19.998047 L 27 9.9980469 C 27 6.1390469 23.858047 3 19.998047 3 L 9.9980469 3 z M 22 7 C 22.552 7 23 7.448 23 8 C 23 8.552 22.552 9 22 9 C 21.448 9 21 8.552 21 8 C 21 7.448 21.448 7 22 7 z M 15 9 C 18.309 9 21 11.691 21 15 C 21 18.309 18.309 21 15 21 C 11.691 21 9 18.309 9 15 C 9 11.691 11.691 9 15 9 z M 15 11 A 4 4 0 0 0 11 15 A 4 4 0 0 0 15 19 A 4 4 0 0 0 19 15 A 4 4 0 0 0 15 11 z"/></svg>
                            </a>

                            <a href="https://www.linkedin.com/company/parque-de-inova%C3%A7%C3%A3o-tecnol%C3%B3gica-de-joinville-e-regi%C3%A3o---inovaparq/" target="_blank">
                                <svg fill="#000000" viewBox="0 0 30 30" width="30px" height="30px"><path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M10.496,8.403 c0.842,0,1.403,0.561,1.403,1.309c0,0.748-0.561,1.309-1.496,1.309C9.561,11.022,9,10.46,9,9.712C9,8.964,9.561,8.403,10.496,8.403z M12,20H9v-8h3V20z M22,20h-2.824v-4.372c0-1.209-0.753-1.488-1.035-1.488s-1.224,0.186-1.224,1.488c0,0.186,0,4.372,0,4.372H14v-8 h2.918v1.116C17.294,12.465,18.047,12,19.459,12C20.871,12,22,13.116,22,15.628V20z"/></svg>
                            </a>

                            <a href="https://www.youtube.com/channel/UCREJOdoB5KZqP4KXQc28Caw" target="_blank">
                                <svg fill="#000000" viewBox="0 0 50 50" width="30px" height="30px"><path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"/></svg>
                            </a>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}