import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { collection, addDoc, serverTimestamp, updateDoc, doc, query, onSnapshot } from 'firebase/firestore'

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css'

import Inputmask from 'inputmask'

export function AdminContato() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()

    const [whatsapp, setWhatsapp] = useState('')

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            if(params.id) {
                const q = query(collection(database, "contatos"))
                
                onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    })
    
                    setWhatsapp(items[0].whatsapp)
    
                    return items
                })
            }
        }
        
        return () => {
            isMounted = false
        }
    }, [])

    async function handleCreation(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        addDoc(collection(database, 'contatos'), {
            whatsapp,
            created_at: serverTimestamp()
        })
        .then(() => {
            location.href = '/admin/contatos'
        }) 
    }

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            
            await updateDoc(doc(database, 'contatos', params.id), {
                whatsapp,
            })
            
            toast('Item editado com sucesso', { type: 'success' })

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    useEffect(() =>{
        Inputmask({"mask": "(99) 99999-9999"}).mask('#whatsapp')
    }, [])

    return (
        <>
            { params.id ? (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/editar contato</span>

                                <Link 
                                    to="/admin/contatos" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none',  
                                        display: 'inline-block' , 
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleEdit} encType="multipart/form-data">

                                    <div>
                                        <label htmlFor="whatsapp">Whatsapp</label>
                                        <input type="text" id="whatsapp" value={whatsapp} onChange={event => setWhatsapp(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Editar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                <span>/cadastrar contato</span>

                                <Link 
                                    to="/admin/contatos" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none',  
                                        display: 'inline-block' , 
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleCreation} encType="multipart/form-data">

                                    <div>
                                        <label htmlFor="whatsapp">Whatsapp</label>
                                        <input type="text" id="whatsapp" value={whatsapp} onChange={event => setWhatsapp(event.target.value)} />
                                    </div>

                                    <div>
                                        <button type="submit">Cadastrar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) }
            
        </>
    )
}