import { useContext } from 'react'
import { Link } from 'react-router-dom'

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/comoAbrirUmaStartup.module.scss'
import 'swiper/css'

export function JornadaDoEmpreendedor() {
    const { user, userSettings } = useContext<any>(AuthContext)

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />
                
                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/jornada do empreendedor</span>

                            <Link 
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Saiba tudo sobre recursos disponíveis de formento à inovação.</span>

                                <p>O Inovaparq compartilha com as startups, um Boletim de Oportunidades de recursos em editais nacionais, elaborado pela Agência de Inovação e Transferência de Tecnologia da Univille – Agitte, além de compartilhar relatórios de acesso à recursos, elaborados por outras entidades como Sebrae e outros.</p>

                                <p>Os recursos de fomento à inovação estão disponíveis em forma de políticas, programas e financiamentos.</p>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>Os recursos de fomento à inovação podem ser:</p>

                                <ul>
                                    <li>Apoio financeiro direto</li>
                                    <li>Apoio financeiro indireto</li>
                                    <li>Fomento para projetos de pd&i</li>
                                    <li>Editais e licitações</li>
                                    <li>Outras formas de apoio</li>
                                </ul>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>Algumas instituições como: FINEP, BNDES, FAPESC e CNPq disponibilizam algumas formas para viabilizar o acesso aos recursos de fomento à inovação, por meio de:</p>

                                <ul>
                                    <li>Chamadas Públicas</li>
                                    <li>Subvenção Econômica</li>
                                    <li>Inova Brasil</li>
                                    <li>Prêmio FINEP</li>
                                    <li>Bolsa RHAE</li>
                                    <li>BNDES Inovação</li>
                                    <li>Cartão BNDES</li>
                                    <li>FUNTEC</li>
                                </ul>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>No Brasil, o Sebrae também é uma entidade que também oferece apoio para o empreendedorismo e para inovação, por meio de:</p>

                                <ul>
                                    <li>Programas de Consultoria Tecnológica</li>
                                    <li>Programas de Agentes Locais de Inovação</li>
                                    <li>Programas SEBRAE de Incubadora de Empresas</li>
                                    <li>SIBRATEC</li>
                                    <li>E outros programas</li>
                                </ul>

                                <p>O governo federal oferece Incentivos Fiscais para fomento em inovação para médias e grandes empresas, por meio das seguintes leis:</p>

                                <ul>
                                    <li>Lei do Bem</li>
                                    <li>Lei de Informática</li>
                                </ul>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>No Brasil, algumas empresas como Petrobrás, Celesc, SCGás, e outras, oferecem Recursos Privados, para inovação e pesquisa no formato de:</p>

                                <ul>
                                    <li>Projetos de cooperação tecnológica</li>
                                </ul>

                                <p>Os instrumentos de apoio à inovação podem ser para Empresas ou para ICT’s:</p>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>Exemplos de instrumento de apoio à inovação para Empresas:</p>

                                <ul>
                                    <li>
                                        Reembolsáveis:
                                        
                                        <ul>
                                            <li> Inova Brasil</li>
                                            <li>BNDES</li>
                                        </ul>
                                    </li>
                                    <li> Subvenção Econômica</li>
                                </ul>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>Exemplos de instrumento de apoio à inovação para ICT’s:</p>

                                <ul>
                                    <li>
                                        Não Reembolsáveis:

                                        <ul>
                                            <li>FINEP</li>
                                            <li>CNPq</li>
                                            <li>BNDES</li>
                                            <li>Outros órgãos</li>
                                            <li>Empresas privadas</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Exemplos de apoio financeiro direto:

                                        <ul>
                                            <li>Capital de risco</li>
                                            <li>Investimento anjo</li>
                                            <li>Seed Money</li>
                                            <li>Venture Capital</li>
                                            <li>Private Equity</li>
                                            <li> Investimento Direto (Bolsa de valores)</li>
                                            <li>Equity Crowdfunding</li>
                                            <li>Corporate Venturing</li>
                                            <li>Renda variável</li>
                                            <li>Títulos de Dívida</li>
                                            <li>ODS Bonds</li>
                                            <li>Innovation Bonds</li>
                                            <li>Green Bonds</li>
                                            <li>Social Bonds</li>
                                            <li>BNDES - Cartão BNDES</li>
                                            <li>BNDES - Soluções Tecnológicas</li>
                                            <li>MPME Inovadora</li>
                                        </ul>
                                    </li>
                                    <li>
                                        Outras formas de apoio financeiro:

                                        <ul>
                                            <li>Rede de interação/cooperação</li>
                                            <li>Soluções tecnológicas</li>
                                            <li>Fundos patrimoniais (Endowments)</li>
                                            <li>Encomendas tecnológicas</li>
                                            <li>Infraestrutura</li>
                                            <li>Prestação de serviços</li>
                                        </ul>
                                    </li>
                                </ul>

                                <p style={{ fontSize: 16, fontWeight: 'bold', lineHeight: '120%' }}>Onde saber ou buscar mais sobre inovação e recursos:</p>

                            <ul>
                                <li><a target="_blank" href="http://www.inovacaotecnologica.com.br/">http://www.inovacaotecnologica.com.br/</a></li>
                                <li><a target="_blank" href="http://criatividadeaplicada.com/">http://criatividadeaplicada.com/</a></li>
                                <li><a target="_blank" href="http://www.portalinovacao.mct.gov.br/">http://www.portalinovacao.mct.gov.br/</a></li>
                                <li><a target="_blank" href="http://inovacaomarketing.com/">http://inovacaomarketing.com/</a></li>
                                <li><a target="_blank" href="http://www.inovacao.unicamp.br/">http://www.inovacao.unicamp.br/</a></li>
                                <li><a target="_blank" href="http://www.inovacao.usp.br/">http://www.inovacao.usp.br/</a></li>
                                <li><a target="_blank" href="http://inovacaotecnologica.ibict.br/">http://inovacaotecnologica.ibict.br/</a></li>
                                <li><a target="_blank" href="http://www.innonet.org/">http://www.innonet.org/</a></li>
                                <li><a target="_blank" href="http://www.inpi.gov.br/">http://www.inpi.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.inmetro.gov.br/">http://www.inmetro.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.compete.org/">http://www.compete.org/</a></li>
                                <li><a target="_blank" href="http://www.innovation.ca/en">http://www.innovation.ca/en</a></li>
                                <li><a target="_blank" href="http://www.anpei.org.br/">http://www.anpei.org.br/</a></li>
                                <li><a target="_blank" href="http://www.mbc.org.br/">http://www.mbc.org.br/</a></li>
                                <li><a target="_blank" href="http://www.anprotec.org.br/">http://www.anprotec.org.br/</a></li>
                                <li><a target="_blank" href="http://www.fapesp.br/">http://www.fapesp.br/</a></li>
                                <li><a target="_blank" href="http://www.cgee.org.br/">http://www.cgee.org.br/</a></li>
                                <li><a target="_blank" href="http://www.abbi.com.br/">http://www.abbi.com.br/</a></li>
                                <li><a target="_blank" href="http://www.fapergs.rs.gov.br/">http://www.fapergs.rs.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.finep.gov.br/">http://www.finep.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.capes.gov.br/">http://www.capes.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.sebrae.com.br/">http://www.sebrae.com.br/</a></li>
                                <li><a target="_blank" href="http://www.sebrae-sc.com.br/">http://www.sebrae-sc.com.br/</a></li>
                                <li><a target="_blank" href="http://www.brde.com.br/">http://www.brde.com.br/</a></li>
                                <li><a target="_blank" href="http://www.bndes.gov.br/">http://www.bndes.gov.br/</a></li>
                                <li><a target="_blank" href="http://www.abdi.com.br/">http://www.abdi.com.br/</a></li>
                                <li><a target="_blank" href="http://www.infotec.org">http://www.infotec.org</a></li>
                                <li><a target="_blank" href="http://www.oecd.org/">http://www.oecd.org/</a></li>
                                <li><a target="_blank" href="http://www.oti.ipt.br/">http://www.oti.ipt.br/</a></li>
                                <li><a target="_blank" href="https://bossainvest.com/">https://bossainvest.com/</a></li>
                                <li><a target="_blank" href="https://fcjventurebuilder.com/">https://fcjventurebuilder.com/</a></li>
                                <li><a target="_blank" href="https://www.btgpactual.com/">https://www.btgpactual.com/</a></li>
                                <li><a target="_blank" href="https://inovaparq.com.br/">https://inovaparq.com.br/</a></li>
                                <li><a target="_blank" href="https://www.joinvalle.com.br/">https://www.joinvalle.com.br/</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}