import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query, where } from "firebase/firestore"

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/servicosDisponiveis.module.scss'
import 'swiper/css';

export function AdminStartups() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [residentes, setResidentes] = useState<any | null>([])
    const [parceirosAcademicos, setParceirosAcademicos] = useState<any | null>([])
    const [empresasConveniadas, setEmpresasConveniadas] = useState<any | null>([])

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'residentes')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setResidentes(items)
            })
    
            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'parceiros-academicos')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setParceirosAcademicos(items)
            })
    
            onSnapshot(query(collection(database, "startups-no-parque"), where('categoria', '==', 'empresas-conveniadas')), (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setEmpresasConveniadas(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <Link 
                                to="/admin/startup" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    cadastrar nova startup
                            </Link>

                            <Link 
                                to="/admin" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.descriptionServices}>
                            <div className={styles.titleServices}>
                                <span>Residentes</span>

                                <div className="img-container">
                                    {residentes.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/admin/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>

                                                <div className={styles.delete} style={{ top: 10, right: 10 }}>
                                                    <Delete id={item.id} table="startups-no-parque" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={styles.descriptionServices} style={{ marginTop: 30 }}>
                            <div className={styles.titleServices}>
                                <span>Parceiros Acadêmicos</span>

                                <div className="img-container">
                                    {parceirosAcademicos.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/admin/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>

                                                <div className={styles.delete} style={{ top: 10, right: 10 }}>
                                                    <Delete id={item.id} table="startups-no-parque" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={styles.descriptionServices} style={{ marginTop: 30 }}>
                            <div className={styles.titleServices}>
                                <span>Empresas Conveniadas</span>

                                <div className="img-container">
                                    {empresasConveniadas.map((item: any) => {
                                        return (
                                            <div key={item.id} style={{ position: 'relative' }}>
                                                <Link to={`/admin/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                                    <div className="img-holder">
                                                        <img src={item.imageUrl} alt="" />
                                                    </div>
                                                </Link>

                                                <div className={styles.delete} style={{ top: 10, right: 10 }}>
                                                    <Delete id={item.id} table="startups-no-parque" />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}