import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom'
import { collection, addDoc, serverTimestamp, updateDoc, doc, query, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes} from 'firebase/storage'

import { database, storage }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css';

export function UserPerfil() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()

    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [imagem, setImagem] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        let isMounted = true

        if(isMounted) {
            if(params.id) {
                const q = query(collection(database, "userSettings"));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    });

                    setImageUrl(items[0].imageUrl)
                    setName(items[0].name)
                    setId(items[0].id)

                    return items
                });
            }
        }

        return () => {
            isMounted = false;
        }
    }, [])

    useEffect(() => {
        if(imagem != '') {
            setImageUrl(URL.createObjectURL(imagem[0]))
        }
    }, [imagem])

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            if(imagem == ''){
                await updateDoc(doc(database, 'userSettings', id), {
                    name
                })
            } else {
                const filename = new Date().getTime()
                
                const imageRef = ref(storage, `images/users/${filename + imagem[0].name}`)
                uploadBytes(imageRef, imagem[0]).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(async (imageUrl) => {
                        await updateDoc(doc(database, 'userSettings', id), {
                            imageUrl,
                            name
                        })
                    }) 
                })
            }  
            
            toast('Item editado com sucesso', { type: 'success' })

            document.querySelector('button').innerHTML = `Atualizar`
            document.querySelector('button').removeAttribute('disabled')
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Atualizar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    return (
        <>
            { params.id ? (
                <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                    <Header />

                    <div className={styles.dashboardHolder}>
                        <div className={styles.servicosDisponiveisDescription}>
                            <div style={{ 
                                display: 'flex', 
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: 20
                            }}>
                                <span>/editar perfil</span>

                                <Link 
                                    to="/" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none', 
                                        display: 'inline-block',
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        voltar
                                </Link>
                            </div>

                            <div className={styles.capacitacoesHolder}>
                                <form onSubmit={handleEdit} encType="multipart/form-data">
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div>
                                                <label htmlFor="imagem">
                                                    { imageUrl == '' ? (
                                                        <div 
                                                            style={{ 
                                                                background: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#000',
                                                                fontSize: 20,
                                                                borderRadius: 50,
                                                                width: 60,
                                                                border: '5px solid #e1e1e1',
                                                                height: 60,
                                                                marginRight: 20
                                                            }}>+</div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                background: 'white',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                color: '#000',
                                                                fontSize: 20,
                                                                borderRadius: 50,
                                                                width: 60,
                                                                border: '5px solid #e1e1e1',
                                                                height: 60,
                                                                marginRight: 20,
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            <img 
                                                                src={imageUrl} 
                                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                                alt="" 
                                                            />
                                                        </div>
                                                    ) }

                                                    <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                                </label>
                                            </div>

                                            <div style={{ flex: 1 }}>
                                                <label htmlFor="nome">Nome</label>
                                                <input type="text" value={name} onChange={event => setName(event.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit">Atualizar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            ) : '' }
            
        </>
    )
}