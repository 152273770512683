import { useState, useEffect, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import { collection, query, onSnapshot } from 'firebase/firestore'

import { database }  from '../services/firebase'

import { Header } from '../components/header'

import { AuthContext } from '../App'

import styles from '../styles/pages/servicosDisponiveis.module.scss';
import 'swiper/css';

export function Destaque() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    const params = useParams()

    useEffect(() => {
        const q = query(collection(database, "destaques"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const items: any = [];
            
            querySnapshot.forEach((doc) => {
                if(doc.id == params.id){
                    items.push({ id: doc.id, ...doc.data() })
                }
            });

            setList(items)

            return items
        });
    })

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                {list.map((item: any) => {
                    return (
                        <div key={item.id} className={styles.dashboardHolder}>
                            <div className={styles.servicosDisponiveisDescription}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                                    <span>/destaque</span>

                                    <Link 
                                        to="/" 
                                        style={{ 
                                            color: '#fff', 
                                            textDecoration: 'none', 
                                            marginBottom: 10, 
                                            display: 'inline-block' 
                                        }}>
                                            /voltar
                                    </Link>
                                </div>

                                <div className={styles.thumb}>
                                    <img src={item.imageUrl} alt="" />
                                </div>

                                <div className={styles.descriptionServices}>
                                    <div className={styles.titleServices}>
                                        <span>{item.titulo}</span>
                                        <p>{item.descricao}</p>
                                    </div>
                                </div>

                                <div className={styles.servicesButton}>
                                    <a href={item.link} target="_blank">Acesse o site oficial</a>
                                </div>
                            </div>  
                        </div>
                    )
                })}
            </div>
        </>
    )
}