import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { onSnapshot, collection, query } from "firebase/firestore";

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/capacitacoes.module.scss';
import 'swiper/css';

export function UserStartups() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "startups"))
        
        let isMounted = true

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });
    
                const filtered = (value: any) => {
                    if (value.owner.id === userSettings?.id) 
                  
                    return value
                }
                
                const filteredItems = items.filter(filtered)
    
                setList(filteredItems)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ 
                            display: 'flex', 
                            justifyContent: 'space-between', 
                            alignItems: 'center',
                            marginBottom: 20
                        }}>
                            <Link           
                                to="/" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none', 
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>

                            { list?.length > 0 ? '' : (
                                <Link 
                                    to="/user/startup" 
                                    style={{ 
                                        background: '#fff', 
                                        textDecoration: 'none', 
                                        display: 'inline-block',
                                        color: '#000',
                                        padding: '5px 10px',
                                        borderRadius: 20
                                    }}>
                                        cadastrar nova startup
                                </Link>

                            ) }
                        </div>

                        {list?.map((item: any) => {
                            return (
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <Link to={`/user/startup/${item.id}`} style={{ textDecoration: 'none' }}>
                                        <div className={styles.capacitacoesHolder}>
                                            <div className={styles.capacitacoes} style={{ alignItems: 'center' }}>
                                                <div>
                                                    <div
                                                        style={{
                                                            background: 'white',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            color: '#000',
                                                            fontSize: 20,
                                                            borderRadius: 50,
                                                            width: 60,
                                                            border: '5px solid #e1e1e1',
                                                            height: 60,
                                                            marginRight: 20,
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        <img 
                                                            src={item.imageUrl} 
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                            alt="" 
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.capacitacoesInfos}>
                                                    <span><strong>Nome da Startup:</strong></span>
                                                    <span style={{ fontSize: 22, lineHeight: '30px' }}>{item.nome}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className={styles.delete}>
                                        <Delete id={item.id} table="startups" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}