import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query } from 'firebase/firestore'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import { database }  from '../services/firebase'

import styles from '../styles/pages/capacitacoes.module.scss'
import 'swiper/css'
import 'swiper/css/pagination'

export const Destaques = () => {
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        let isMounted = true

        if (isMounted) {
            const q = query(collection(database, "destaques"))
            onSnapshot(q, (querySnapshot) => {
                const items: any = [];
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                });

                setList(items)
            });
        }

        return () => {
            isMounted = false
        }
    }, [])

    return(
        <div className={styles.swiperNotices}>
            <Swiper
                modules={[ Pagination ]}
                spaceBetween={5}
                slidesPerView={1}
                loop={false}
                pagination={{ clickable: true }}
            >
                {list?.map((item: any) => {
                    return (
                        <SwiperSlide key={item.id}>
                            <Link to={`/destaque/${item.id}`} style={{ textDecoration: 'none' }}>
                                <div className={styles.notice}>
                                    <div className={styles.noticeImg}>
                                        <img src={item.imageUrl} alt="" />
                                    </div>

                                    <div className={styles.noticeDescription}>
                                        <p>{item.descricao.substring(0, 100)}...</p>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>                   
        </div>
    )
}