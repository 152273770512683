import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query } from 'firebase/firestore'

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/servicosDisponiveis.module.scss'
import 'swiper/css'

export function AdminServicosDisponiveis() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "servicosDisponiveis"));
        
        let isMounted = true

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = []
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setList(items)
            })
        }

        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <Link 
                                to="/admin/servico-disponivel" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    cadastrar novo serviço
                            </Link>

                            <Link 
                                to="/admin" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , 
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            return (
                                <div key={item.id} style={{ position: 'relative' }}>
                                    <Link to={`/admin/servico-disponivel/${item.id}`} style={{ textDecoration: 'none' }}>
                                        <div className={styles.descriptionServices} style={{ display: 'flex' }}>
                                            <div className={styles.capacitacoesThumb}>
                                                <img src={item.imageUrl} alt="" />
                                            </div>

                                            <div className={styles.titleServices}>
                                                <span>{item.titulo}</span>

                                                <p>{item.descricao}</p>

                                                <p>Tipo: {item.tipo} | Local: {item.sala}</p>
                                            </div>
                                        </div>
                                    </Link>

                                    <div className={styles.delete}>
                                        <Delete id={item.id} table="servicosDisponiveis" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}