import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { onSnapshot, collection, query, orderBy } from 'firebase/firestore'

import { database }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'
import { Delete } from '../admin/delete'

import styles from '../../styles/pages/servicosDisponiveis.module.scss'
import 'swiper/css'

export function AdminAgendas() {
    const { user, userSettings } = useContext<any>(AuthContext)
    const [list, setList] = useState<any | null>([])

    useEffect(() => {
        const q = query(collection(database, "agendas"), orderBy('data'))

        let isMounted = true

        if(isMounted) {
            onSnapshot(q, (querySnapshot) => {
                const items: any = []
                
                querySnapshot.forEach((doc) => {
                    items.push({ id: doc.id, ...doc.data() })
                })
    
                setList(items)
            })
        }
        
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <>
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <Link 
                                to="/admin/agenda" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block',
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    cadastrar nova agenda
                            </Link>

                            <Link 
                                to="/admin" 
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , 
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        {list.map((item: any) => {
                            const date = String(new Date(item.data.seconds * 1000).toLocaleDateString('pt-BR', {
                                weekday: 'long' ,
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            })).split( ' ' )

                            return (
                                <div key={item.id} style={{ position: 'relative', overflow: 'hidden' }}>
                                    <Link to={`/admin/agenda/${item.id}`} style={{ textDecoration: 'none' }}>
                                        <div className={styles.descriptionServices} style={{ display: 'flex' }}>
                                            <div className={styles.capacitacoesThumb}>
                                                <img src={item.imageUrl} alt="" />
                                            </div>

                                            <div className={styles.titleServices}>
                                                <span style={{ fontSize: 16, lineHeight: '110%' }}>{item.titulo} <br /><small style={{ opacity: .6, fontWeight: 'normal' }}>{ date[1] } { date[2] } { date[3] }</small></span>

                                                <p>{item.descricao.substring(0,100)}...</p>

                                                {/* <div className={styles.servicesButton}>
                                                    <Link to={item.link}>Acessar</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                    </Link>

                                    <div className={styles.delete}>
                                        <Delete id={item.id} table="agendas" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

        </>
    )
}