import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { collection, updateDoc, doc, query, onSnapshot } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytes} from 'firebase/storage'

import { database, storage }  from '../../services/firebase'

import { AuthContext } from '../../App'

import { Header } from '../../components/header'

import styles from '../../styles/pages/capacitacoes.module.scss'
import 'react-toastify/dist/ReactToastify.css'

export function AdminEditAula() {
    const { user, userSettings } = useContext(AuthContext)

    const params = useParams()

    const [id, setId] = useState('')
    const [titulo, setTitulo] = useState('')
    const [autor, setAutor] = useState('')
    const [duracao, setDuracao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [idVideo, setIdVideo] = useState('')
    const [pontuacao, setPontuacao] = useState(0)
    const [ordem, setOrdem] = useState(0)
    const [imagem, setImagem] = useState('')
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        if(params.id) {
            const q = query(collection(database, "aulas"))
            
            let isMounted = true

            if(isMounted) {
                onSnapshot(q, (querySnapshot) => {
                    const items = [];
                    
                    querySnapshot.forEach((doc) => {
                        if(doc.id == params.id){
                            items.push({ id: doc.id, ...doc.data() })
                        }
                    });
    
                    setImageUrl(items[0].imageUrl)
                    setTitulo(items[0].titulo)
                    setDuracao(items[0].duracao)
                    setPontuacao(items[0].pontuacao)
                    setOrdem(items[0].ordem)
                    setIdVideo(items[0].idVideo)
    
                    return items
                })
            }

            return () => {
                isMounted = false
            }
        }
    }, [])

    useEffect(() => {
        if(imagem != '') {
            setImageUrl(URL.createObjectURL(imagem[0]))
        }
    }, [imagem])

    async function handleEdit(event) {
        event.preventDefault()

        document.querySelector('button').innerHTML = `
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                class="spin feather feather-loader"
            >
                <line x1="12" y1="2" x2="12" y2="6"></line>
                <line x1="12" y1="18" x2="12" y2="22"></line>
                <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
                <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
                <line x1="2" y1="12" x2="6" y2="12"></line>
                <line x1="18" y1="12" x2="22" y2="12"></line>
                <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
                <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
            </svg>
        `
        document.querySelector('button').setAttribute('disabled', 'disabled')

        try {
            if(imagem == ''){
                await updateDoc(doc(database, 'aulas', params.id), {
                    titulo,
                    duracao,
                    idVideo,
                    pontuacao: Number(pontuacao),
                    ordem: Number(ordem),
                })
            } else {
                const filename = new Date().getTime()
                
                const imageRef = ref(storage, `images/${filename + imagem[0].name}`)
                await uploadBytes(imageRef, imagem[0]).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then(async (imageUrl) => {
                        await updateDoc(doc(database, 'aulas', params.id), {
                            imageUrl,
                            titulo,
                            duracao,
                            idVideo,
                            pontuacao: Number(pontuacao),
                            ordem: Number(ordem),
                        })
                    }) 
                })
            }  
            
            toast('Item editado com sucesso', { type: 'success' })

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
            
        } catch(error) {
            toast('Tivemos um problema ao editar este item', { type: 'error' })
            console.log(error)

            document.querySelector('button').innerHTML = `Editar`
            document.querySelector('button').removeAttribute('disabled')
        }
    }

    return (
        <>  
            <div className={`${ styles.content } ${ styles.contentAdmin } ${ userSettings?.type }`}>
                <Header />

                <div className={styles.dashboardHolder}>
                    <div className={styles.servicosDisponiveisDescription}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                            <span>/editar aula</span>

                            <Link 
                                to={`/admin/capacitacao/${ params.curso }`}
                                style={{ 
                                    background: '#fff', 
                                    textDecoration: 'none',  
                                    display: 'inline-block' , 
                                    color: '#000',
                                    padding: '5px 10px',
                                    borderRadius: 20
                                }}>
                                    voltar
                            </Link>
                        </div>

                        <div className={styles.capacitacoesHolder}>
                            <form onSubmit={handleEdit} encType="multipart/form-data">
                                <div>
                                    <label htmlFor="imagem">
                                        { imageUrl == '' ? (
                                            <div className={styles.thumbnail}>+</div>
                                        ) : (
                                            <div className={styles.thumbnail}>
                                                <img 
                                                    src={imageUrl} 
                                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} 
                                                    alt="" 
                                                />
                                            </div>
                                        ) }

                                        <input type="file" style={{ display: 'none' }} name="imagem" id="imagem" onChange={event => setImagem(event.target.files)} />
                                    </label>
                                </div>
                                
                                <div>
                                    <label htmlFor="titulo">Título</label>
                                    <input type="text" value={titulo} onChange={event => setTitulo(event.target.value)} />
                                </div>

                                <div>
                                    <label htmlFor="duracao">Duração</label>
                                    <input type="text" value={duracao} onChange={event => setDuracao(event.target.value)} />
                                </div>

                                <div>
                                    <label htmlFor="idVideo">ID do vídeo</label>
                                    <input type="text" value={idVideo} onChange={event => setIdVideo(event.target.value)} />
                                </div>

                                <div>
                                    <label htmlFor="pontuacao">Pontuação</label>
                                    <input type="text" value={pontuacao} onChange={event => setPontuacao(event.target.value)} />
                                </div>

                                <div>
                                    <label htmlFor="ordem">Ordem</label>
                                    <input type="text" value={ordem} onChange={event => setOrdem(event.target.value)} />
                                </div>

                                <div>
                                    <button type="submit">Editar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}